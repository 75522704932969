<template>
	<v-sheet class="customer" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<div class="d-flex">
						<p class="my-auto font-level-3-bold">
							<ShowValue :object="customer" object-key="display_name" label="customer name"></ShowValue>
							<v-chip color="cyan" class="ml-2 mr-2" label text-color="white">{{
								customer.barcode
							}}</v-chip>
						</p>
						<v-spacer></v-spacer>

						<v-btn
							v-if="getPermission('customer:create')"
							v-on:click="doAction('clone')"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-content-duplicate</v-icon>Clone</v-btn
						>

						<v-btn
							v-if="getPermission('customer:update')"
							v-on:click="doAction('edit')"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
						>

						<template v-if="getPermission('customer:delete')">
							<v-btn
								v-if="!customer.is_owner"
								v-on:click="doAction('delete')"
								color="blue darken-4 text-white"
								class="ml-2"
								depressed
								tile
								><v-icon small left>mdi-delete</v-icon>Delete</v-btn
							>
						</template>
						<template v-if="!customer.is_owner">
							<template v-if="getPermission('customer:delete')">
								<v-btn
									v-if="customer.activated"
									v-on:click="doAction('deactivate')"
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-cancel</v-icon>De-activate</v-btn
								>
								<v-btn
									v-else
									v-on:click="doAction('activate')"
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-check-all</v-icon>Activate</v-btn
								>
							</template>
						</template>
						<template v-if="false && getPermission('property:create')">
							<v-btn
								v-if="customerTab == 'address-book'"
								v-on:click="doAction('new-address')"
								color="blue darken-4 text-white"
								class="ml-2"
								depressed
								tile
								><v-icon small left>mdi-playlist-plus</v-icon>Add New Property</v-btn
							>
						</template>
					</div>
					<div class="d-flex mt-4">
						<div class="w-10 pr-3">
							<ImageTemplate circle :src="customer.image"></ImageTemplate>
						</div>
						<div class="w-100 pr-3">
							<div class="d-flex mt-4">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Customer # : </span>
										<span class="fw-500">
											<ShowValue :object="customer" object-key="barcode" label="customer #"></ShowValue
										></span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Company Name : </span>
										<span class="fw-500">
											<span class="fw-500">
												<ShowValue
													:object="customer"
													object-key="company_name"
													label="company name"
												></ShowValue>
											</span>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Display Name : </span>
										<span class="fw-500">
											<ShowValue :object="customer" object-key="display_name" label="display name"></ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Email Address : </span>
										<span class="fw-500">
											<ShowValue :object="customer" object-key="email" label="email address"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Phone : </span>
										<span class="fw-500">
											<ShowValue :object="customer" object-key="phone" label="phone"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">UEN : </span>
										<ShowValue :object="customer" object-key="uen" label="UEN"></ShowValue>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Address : </span>
										<span class="fw-500">
											<ShowValue
												:object="customer.shipping_address"
												object-key="address_line_1"
												label="Address"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Country : </span>
										<span class="fw-500">
											<ShowValue
												:object="customer.shipping_address"
												object-key="address_country"
												label="country"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Postal Code : </span>
										<ShowValue
											:object="customer.shipping_address"
											object-key="address_postal_code"
											label="postalcode"
										></ShowValue>
									</div>
								</div>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<v-row>
				<v-col md="12">
					<v-layout class="page-summary show">
						<v-flex class="summary-details bg-light-red light-blue-bg" md4>
							<v-icon color="blue">mdi-file-chart-outline</v-icon>
							<div class="name text-capitalize">All Transactions</div>
							<div class="number red--text">{{ allTransactions }}</div>
						</v-flex>
						<v-flex class="summary-details bg-light-red light-blue-bg" md4>
							<v-icon color="green">mdi-file-chart-outline</v-icon>
							<div class="name text-capitalize">Issue</div>
							<div class="number red--text">{{ customerIssue }}</div>
						</v-flex>
						<v-flex class="summary-details bg-light-red light-blue-bg" md4>
							<v-icon color="orange">mdi-file-chart-outline</v-icon>
							<div class="name text-capitalize">Receive</div>
							<div class="number red--text">{{ customerReceive }}</div>
						</v-flex>
					</v-layout>
				</v-col>
			</v-row>
			<div>
				<v-tabs
					v-model="customerTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Details</v-tab
					>
					<v-tab href="#transactions" class="pr-8">
						<v-icon small left>mdi-share-outline</v-icon> Transactions
					</v-tab>
					<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon> Files</v-tab>
					<v-tab href="#checked-out" v-if="false">
						<v-icon small left>mdi-share-outline</v-icon> Checked Out</v-tab
					>
					<v-tab href="#reservation" v-if="false">
						<v-icon small left>mdi-calendar-check</v-icon> Reservations</v-tab
					>
					<v-tab href="#asset" class="pr-8"> Asset </v-tab>
					<v-tab href="#asset-stock" class="pr-8"> Asset Stock </v-tab>

					<!-- <v-tab href="#address-book"> <v-icon small left>mdi-format-list-text</v-icon> Address</v-tab> -->
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon> Logs</v-tab>
				</v-tabs>

				<v-tabs-items v-model="customerTab">
					<v-tab-item value="detail">
						<v-expansion-panels
							flat
							accordion
							tile
							class="mt-5 transparent-panels overflow-y"
							style="max-height: calc(100vh - 250px)"
						>
							<v-expansion-panel class="active">
								<v-expansion-panel-header v-if="false"> Customer Details </v-expansion-panel-header>
								<v-expansion-panel-content v-if="false">
									<table width="100%" class="detail-table scattered-table">
										<tr>
											<th width="250px">Customer #</th>
											<td>
												<ShowValue :object="customer" object-key="barcode" label="customer #"></ShowValue>
											</td>
											<th width="250px">UEN</th>
											<td>
												<ShowValue :object="customer" object-key="uen" label="UEN"></ShowValue>
											</td>
										</tr>
										<tr>
											<th width="250px">Company Name</th>
											<td>
												<ShowValue
													:object="customer"
													object-key="company_name"
													label="company name"
												></ShowValue>
											</td>
											<th width="250px">Display Name</th>
											<td>
												<ShowValue
													:object="customer"
													object-key="display_name"
													label="display name"
												></ShowValue>
											</td>
										</tr>
										<tr>
											<th width="250px">Email Address</th>
											<td>
												<ShowValue :object="customer" object-key="email" label="email address"></ShowValue>
											</td>
											<th width="250px">Phone</th>
											<td>
												<ShowValue :object="customer" object-key="phone" label="phone"></ShowValue>
											</td>
										</tr>
										<tr v-if="false">
											<th width="250px">Primary Contact</th>
											<td>
												<ShowValue
													:object="customer"
													object-key="primary_contact"
													label="primary contact"
												></ShowValue>
											</td>
											<th width="250px">Website</th>
											<td>
												<ShowValue :object="customer" object-key="website" label="website"></ShowValue>
											</td>
										</tr>
										<tr v-if="false">
											<th width="250px">Fax</th>
											<td>
												<ShowValue :object="customer" object-key="fax" label="fax"></ShowValue>
											</td>
											<th width="250px">
												Remarks <br /><span class="text-muted font-small">(For Internal Use)</span>
											</th>
											<td>
												<ShowValue :object="customer" object-key="remarks" label="remarks"></ShowValue>
											</td>
										</tr>
									</table>
								</v-expansion-panel-content>
							</v-expansion-panel>
							<v-expansion-panel>
								<v-expansion-panel-header>
									<v-layout>
										<v-flex md10>Contact Persons ({{ contact_person.length }})</v-flex>
										<v-flex md2 class="text-right">
											<v-icon v-on:click.stop.prevent="addPerson()" dense color="blue darken-4" class="mx-2"
												>mdi-plus-circle-outline</v-icon
											>
										</v-flex>
									</v-layout>
								</v-expansion-panel-header>
								<v-expansion-panel-content class="contact-person-list">
									<template v-if="contact_person.length">
										<v-layout v-for="(person, index) in contact_person" :key="index">
											<v-flex md12>
												<div>
													<p class="text-truncate mb-1 bold-600 text-capitalize">
														{{ person.full_name }}
														<v-chip class="ma-2" label x-small color="green" v-if="person.primary == 1">
															<span class="white--text">Primary</span>
														</v-chip>
													</p>
													<v-menu left tile offset-x>
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																icon
																small
																color="blue darken-4"
																class="btx-icon-top-right"
																v-bind="attrs"
																v-on="on"
															>
																<v-icon small>mdi-cog</v-icon>
															</v-btn>
														</template>

														<v-list small>
															<v-list-item link v-on:click="addPerson(person)">
																<v-list-item-title>Edit</v-list-item-title>
															</v-list-item>
															<v-list-item link v-if="!person.primary" v-on:click="markAsPrimary(person.uuid)">
																<v-list-item-title>Mark as Primary</v-list-item-title>
															</v-list-item>
															<v-list-item link v-if="false">
																<v-list-item-title>Send Email</v-list-item-title>
															</v-list-item>
															<v-list-item link v-if="!person.primary" v-on:click="deletePerson(person.uuid)">
																<v-list-item-title>Delete</v-list-item-title>
															</v-list-item>
														</v-list>
													</v-menu>
												</div>
												<p class="mb-1 text-lowercase" v-if="person.email">
													<v-icon left small>mdi-email</v-icon>{{ person.email }}
												</p>
												<p class="mb-1 text-lowercase" v-if="person.phone">
													<v-icon left small>mdi-phone</v-icon>{{ person.phone }}
												</p>
												<p class="mb-1" v-if="person.display_name">
													<v-icon left small>mdi-account</v-icon> {{ person.display_name }}
												</p>
												<p class="mb-1" v-if="person.designation">
													<v-icon left small>mdi-briefcase</v-icon> {{ person.designation }}
												</p>
											</v-flex>
										</v-layout>
									</template>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-tab-item>
					<v-tab-item value="transactions">
						<template v-if="customer && customer.id">
							<Transaction
								class="mx-4"
								relation-type="customer"
								:relation-type-id="customer.id"
							></Transaction>
						</template>
					</v-tab-item>

					<v-tab-item value="file">
						<Files
							v-if="customerTab == 'file'"
							class="mx-4"
							type-text="Customer Files"
							type="contact"
							:type-uuid="uuid"
							:type-id="customerId"
						></Files>
					</v-tab-item>
					<v-tab-item value="asset">
						<AssetListing
							v-if="customerId && customerTab == 'asset'"
							class="mx-4"
							relation-type="contact"
							:relation-type-id="customerId"
						></AssetListing>
					</v-tab-item>
					<v-tab-item value="asset-stock">
						<AssetStockListing
							v-if="customerId && customerTab == 'asset-stock'"
							class="mx-4"
							relation-type="contact"
							:relation-type-id="customerId"
						></AssetStockListing>
					</v-tab-item>
					<v-tab-item value="checked-out" v-if="false">
						<ReservationListing
							v-if="customerTab == 'checked-out'"
							class="mx-4"
							type-text="Customer"
							type="customer"
							:type-uuid="uuid"
							status="checked-out"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="reservation" v-if="false">
						<ReservationListing
							v-if="customerTab == 'reservation'"
							class="mx-4"
							type-text="Customer"
							type="customer"
							:type-uuid="uuid"
							status="reserved"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="address-book">
						<CustomerAddressBook
							v-if="customerTab == 'address-book'"
							type-text="Customer"
							type="contact"
							:new-address="addressDialog"
							:type-uuid="uuid"
							:contact-person-list="contact_person"
							v-on:close="addressDialog = false"
							v-on:updateContactPersonList="getCustomer"
						></CustomerAddressBook>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="customerTab == 'event'"
							class="mx-4"
							type-text="Customer"
							type="contact"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Customer"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					customer.activated
						? 'To preserve associated records, you could de-activate the Customer instead.'
						: null
				"
			>
				<template v-if="customer.activated" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							deactivateDialog = true;
						"
					>
						De-activate
					</v-btn>
				</template>
			</DeleteTemplate>
			<DeactivateTemplate
				type="Customer"
				:deactivate-text="deactivateText"
				v-on:success="goBack()"
				v-on:close="deactivateDialog = false"
				:deactivate-dialog="deactivateDialog"
				:deactivate-url="deactivateURL"
			>
			</DeactivateTemplate>
			<ActivateTemplate
				type="Customer"
				:activate-text="activateText"
				v-on:success="goBack()"
				v-on:close="activateDialog = false"
				:activate-dialog="activateDialog"
				:activate-url="activateURL"
			>
			</ActivateTemplate>
			<CreateOrUpdatePerson
				:person-dialog="personDialog"
				:vendor-uuid="uuid"
				:person="personModel"
				:contactUuid="customer.uuid"
				v-on:close="personDialog = false"
				v-on:success="getCustomer"
			></CreateOrUpdatePerson>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<style>
.w-15 {
	width: 125px;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
	GetCustomer,
	MarkAsDefaultContactPerson,
	DeleteContactPerson,
} from "@/core/lib/contact.lib";
import ImageTemplate from "@/view/components/Image";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { isEmpty } from "lodash";
import ActivateTemplate from "@/view/components/ActivateTemplate";
import DeactivateTemplate from "@/view/components/DeactivateTemplate";
import EventListing from "@/view/components/EventListing";
import ReservationListing from "@/view/components/ReservationListing";
import CustomerAddressBook from "@/view/components/MemberAddressBook";
import Files from "@/view/components/Files";
import ApiService from "@/core/services/api.service";

import CreateOrUpdatePerson from "@/view/components/CreateOrUpdatePerson";
import AssetListing from "@/view/components/AssetListing";
import Transaction from "@/view/components/Transactionlisting";

import AssetStockListing from "@/view/components/AssetStockListing";

export default {
	name: "customer-detail",
	title: "Detail Customer",
	data() {
		return {
			uuid: null,
			barcode: null,
			activateText: null,
			activateURL: null,
			customerReceive: 0,
			customerIssue: 0,
			allTransactions: 0,
			deactivateText: null,
			deactivateURL: null,
			personDialog: false,
			customerId: 0,
			deleteText: null,
			contact_person: [],
			formValid: true,
			personModel: {},
			deleteURL: null,
			deleteDialog: false,
			addressDialog: false,
			deactivateDialog: false,
			activateDialog: false,
			contentLoaded: false,
			pageLoading: false,
			customerTab: null,
			customer: {},
		};
	},
	methods: {
		getCustomerCount() {
			ApiService.setHeader();
			ApiService.get(`customer-detail-count/${this.customerId}`)
				.then(({ data }) => {
					this.allTransactions = data.allTransactions.toString();
					this.customerIssue = data.customerIssue.toString();
					this.customerReceive = data.customerReceive.toString();
				})
				.catch(() => {});
		},
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "customer-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "clone":
					this.$router.push({
						name: "customer-create",
						query: { clone: this.uuid, t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "deactivate":
					this.deactivateDialog = true;
					break;
				case "activate":
					this.activateDialog = true;
					break;
				case "new-address":
					this.addressDialog = true;
					break;
				case "print-label":
					break;
			}
		},
		markAsPrimary(uuid) {
			MarkAsDefaultContactPerson(uuid)
				.then(() => {
					this.getCustomer();
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		deletePerson(uuid) {
			DeleteContactPerson(uuid)
				.then(() => {
					this.getCustomer();
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		getCustomer() {
			GetCustomer(this.uuid)
				.then((data) => {
					const customer = data.contact;
					this.barcode = customer.barcode;
					this.customer = customer;
					this.customerId = customer.id;
					this.getCustomerCount();
					this.contact_person = data.contact_person;
					this.deleteText = `#${customer.barcode} - ${customer.display_name}`;
					this.deleteURL = `contact/${customer.uuid}`;
					this.activateText = `${customer.display_name}`;
					this.activateURL = `contact/customer/${customer.uuid}/activate`;
					this.deactivateText = `${customer.display_name}`;
					this.deactivateURL = `contact/customer/${customer.uuid}/de-activate`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Contacts", disabled: true },
						{ text: "Customer", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		addPerson(person) {
			this.personDialog = true;
			this.personModel = new Object();
			if (person && !isEmpty(person)) {
				this.personModel = new Object({
					id: person.id || null,
					uuid: person.uuid || null,
					salutation: person.salutation || null,
					first_name: person.first_name || null,
					last_name: person.last_name || null,
					email: person.email || null,
					mobile: person.phone || null,
					display_name: person.display_name || null,
					designation: person.designation || null,
					primary: person.primary || null,
				});
			}
		},
	},
	components: {
		ShowValue,
		CustomerAddressBook,
		EventListing,
		ImageTemplate,
		DeleteTemplate,
		ActivateTemplate,
		CreateOrUpdatePerson,
		Files,
		DeactivateTemplate,
		ReservationListing,
		Transaction,
		AssetListing,
		AssetStockListing,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Contacts", disabled: true },
			{ text: "Customer", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getCustomer();
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
